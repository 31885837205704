import React, { useState } from "react";
import { navigate } from "gatsby";
import { setUser, isLoggedIn } from "../../utils/auth";

const LoginComponent = (props) =>
{
  if (isLoggedIn())
  {
    navigate(`/admin`);
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const handleSubmit = e =>
  {
    e.preventDefault();

    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    const emailValidationState = email !== null && email.length !== 0 && re.test(email);
    const passwordValidationState = password !== null && password.length !== 0;

    setIsEmailValid(emailValidationState);
    setIsPasswordValid(passwordValidationState);

    if (emailValidationState && passwordValidationState)
    {
      props.loginUser(email, password);
    }
  };

  let content;

  if (props.loginUserResults.loading)
  {
    content = <div></div>;
  }
  else if (props.loginUserResults.error)
  {
    content = <div>{ props.loginUserResults.error.message }</div>;
  }
  else if (props.loginUserResults.data)
  {
    content = <div></div>;
    setUser(props.loginUserResults.data.loginUser);
    navigate(`/admin`);
  }
  else
  {
    content = (
      <form
        onSubmit = { handleSubmit }
      >
        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="email"
          >
            Email
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "email"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type = "text"
              name = "email"
              onChange =
              {
                event =>
                {
                  setEmail(event.target.value);
                }
              }
            />
          </div>

          {
            !isEmailValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter a valid email address.
            </p>
          }
        </div>

        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="password"
          >
            Password
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "password"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type="password"
              name="password"
              onChange =
              {
                event =>
                {
                  setPassword(event.target.value);
                }
              }
            />
          </div>

          {
            !isPasswordValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter your password.
            </p>
          }
        </div>
  
        <div
          className = "text-center sm:text-left"
        >
          <button
            className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white"
          >
            Log In
          </button>
        </div>
      </form>
    );
  }

  return (
    <div>
      { content }
    </div>
  );
};

export default LoginComponent;