import React from "react";
import AdminLayout from "../../components/admin/adminLayout";
import LoginComponent from "../../components/admin/loginComponent";
import Apollo from "../../components/apollo";
import { loginUserMutation } from "../../utils/staticQueryFragments";
import SEO from "../../components/seo";

const Login = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `login`] }
        title = "Login"
        isAdmin = { true }
      />
      <Apollo
        propsToInsert =
        {
          (
            queryResults,
            { mutation1, mutation1Results }
          ) => (
            {
              loginUser: (email, password) =>
              {
                mutation1(
                  {
                    variables:
                    {
                      email: email,
                      password: password
                    }
                  }
                );
              },
              loginUserResults: mutation1Results
            }
          )
        }
        mutation1 = { loginUserMutation }
      >
        <LoginComponent />
      </Apollo>
    </AdminLayout>
  );
};

export default Login;